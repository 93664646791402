import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import GatsbyLink from 'gatsby-link';

export default function ParallaxTextRight(props){

  const {
    field_background_image,
    field_button_text,
    field_description_plain,
    field_link,
    field_title
  } = props.data

  let backgroundImage = ''

  if(
    typeof field_background_image !== 'undefined'
    && field_background_image !== null
    && field_background_image !== ''
  ){
    backgroundImage = field_background_image
  }


  return (
    <div class="modern-decorate-banner-section section image-fixd bg-image d-flex align-items-center" style={{ backgroundImage: `url(${backgroundImage})`}}>
      <div class="container-fluid container-fluid-minimus">
        <div class="row">
          <div class="col-md-6 offset-md-6">
            <Parallax offsetYMin={400} offsetYMax={500}>
              <div class="modern-decorate-banner-content">
                {field_link && field_title && <h2><a href={field_link}>{field_title}</a></h2>}
                {field_link && field_button_text && <a class="shop-btn" href={field_link}>{field_button_text}</a>}
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    </div>
  );
};
