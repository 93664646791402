import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import React from 'react';

const CaptchaButton = ({ onVerifyCaptcha }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const clickHandler = async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('contact');

    onVerifyCaptcha(token);
  };

  return (
    <button className="submit" onClick={clickHandler}>
      Send
    </button>
  );
};

const Captcha = ({ onVerifyCaptcha }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey="6Lc0WvkZAAAAAGOMeACAaIB4eGf_S8X7bAu7g9sd"
  >
    <CaptchaButton onVerifyCaptcha={onVerifyCaptcha} />
  </GoogleReCaptchaProvider>
);

export default Captcha
