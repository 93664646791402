import React from 'react';
import { Flex, Box } from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import { StateConsumer } from '../../context/StateContext';
import { excludeNodeEnv } from '../../helpers/DomHelper';
import {
  Layout,
  Features3Col,
  SectionCtaSimple,
  Header1,
  Header2,
  SectionAboutVideo,
  SectionAbout,
  SectionFaq,
  SectionContactDetails,
  ParallaxTextRight,
  HeroSlider,
  NewsletterSubscribe,
  ContactForm,
  RecentBuilds,
  PopularProducts,
} from '.';

const StandardPage = ({ data, pageContext }) => {
  const { formlightPage, allFormlightProduct } = data;

  const { pageParagraphs, pageTitle, pageUrl, pageMeta } = formlightPage;

  const { entityData } = pageContext;

  const products = allFormlightProduct.edges;

  return (
    <Layout metaTitle={pageTitle} metaDesc={pageMeta.title}>
      <StateConsumer>
        {({ myBock }) => {
          return (
            <>
              {pageParagraphs.length > 0 &&
                pageParagraphs.map((block, k) => {
                  // console.log(block)
                  // console.log(block.type)

                  const blockData = block.fields[0];

                  if (block.type === 'formlight_recent_builds') {
                    return <RecentBuilds data={blockData} />;
                  } else if (block.type === 'formlight_simple_cta') {
                    return <SectionCtaSimple data={blockData} />;
                  } else if (block.type === 'formlight_breadcrumb_large') {
                    return <Header2 data={blockData} />;
                  } else if (block.type === 'formlight_breadcrumb_small') {
                    return <Header1 data={blockData} />;
                  } else if (block.type === 'formlight_about_video' && excludeNodeEnv() === true) {
                    return <SectionAboutVideo data={blockData} />;
                  } else if (block.type === 'formlight_about') {
                    return <SectionAbout data={blockData} />;
                  } else if (block.type === 'formlight_contact') {
                    return <SectionContactDetails data={blockData} />;
                  } else if (block.type === 'formlight_parallax_cta') {
                    return <ParallaxTextRight data={blockData} />;
                  } else if (block.type === 'formlight_faq') {
                    return <SectionFaq data={blockData} />;
                  } else if (block.type === 'formlight_features_icon') {
                    return <Features3Col data={blockData} />;
                  } else if (block.type === 'formlight_hero_slider') {
                    return <HeroSlider data={blockData} />;
                  } else if (block.type === 'formlight_featured_products') {
                    return (
                      <PopularProducts
                        data={blockData}
                        products={products}
                        entityData={entityData}
                      />
                    );
                  } else if (block.type === 'formlight_webform') {
                    return <ContactForm data={blockData} />;
                  }
                })}
            </>
          );
        }}
      </StateConsumer>
    </Layout>
  );
};

export const query = graphql`
  query formlightPage($alias: String!) {
    formlightPage(pageUrl: { eq: $alias }) {
      pageUrl
      pageTitle
      pageMeta {
        title
        google
      }
      pageParagraphs {
        type
        id
        fields {
          field_button_text
          field_description
          field_formlight_bg_color
          field_link
          field_title
          field_background_image
          field_description_plain
          field_image_media
          field_video_url
          field_formlight_icon_css_class
          field_google_url
          field_columns
          field_webform
          field_formlight_paragraph_item {
            field_formlight_faq_anwser
            field_formlight_faq_question
            field_description
            field_icon
            field_title
            field_subtitle
            field_background_image
            field_button_text
            field_link
            field_overlay
            field_tab_name
            field_tab_products
            field_background_position
          }
          fieldImageMedia {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fieldBackgroundImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allFormlightProduct {
      edges {
        node {
          id
          nodeId
          nodeName
          pageUrl
          nodeCategory
          nodeImages
          nodeType
          nodeBody
          nodeSticky
          nodePbItems {
            key
            _b
            _c
            _n
            _d
            paint_compatible
            cg_wg_compatible
            cast_guard_compatible
            wattage
            lamp_type_specific
            lens_use
            dim_h
            dim_d
            dim_w
            dim_l
            guard
            lamp_types
            ld
            lens
            lk
            ls
          }
          imageGroup {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   query formlightPage($alias: String!) {
//       formlightPage(pageUrl: {eq: $alias}) {
//         pageUrl
//         pageTitle
//         pageMeta {
//           title
//           google
//         }
//         pageParagraphs {
//           type
//           id
//           fields {
//             field_button_text
//             field_description
//             field_formlight_bg_color
//             field_link
//             field_title
//             field_image_media
//             field_description_plain
//             field_video_url
//             field_formlight_icon_css_class
//             field_google_url
//             field_columns
//             field_webform
//             field_formlight_paragraph_item {
//               field_formlight_faq_anwser
//               field_formlight_faq_question
//               field_description
//               field_icon
//               field_title
//               field_subtitle
//
//             }
//           }
//         }
//       }
//     }
// `

export default StandardPage;
