import React, {useState, useEffect} from 'react';
import { StateConsumer } from '../../context/StateContext'
// import GatsbyLink from 'gatsby-link';
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import SingleProduct from './SingleProduct';

export default function ProductWrapper(props) {

  const {
    items: products,
    entityData
  } = props

  let productCollection = [];
  let productCount = 0;

  if(products !== null) {
    productCollection = products
    productCount = productCollection.length
  }


  const [data, setData] = useState([...productCollection]);
  const [sortType, setSortType] = useState('nodeSticky');


  // let productCount = productCollection.length

  if(data !== null){
    productCount = data.length
  }

  useEffect(() => {

    const sortArray = type => {
      const types = {
        featured: 'nodeSticky',
        atoz: 'nodeName',
        ztoa: 'nodeName',
      };
      const sortProperty = types[type];
      // Numerical, default
      let sorted = [...productCollection].sort((a, b) => b[sortProperty] - a[sortProperty]);
      // AtoZ
      // if (type == 'atoz') {
      if (type === 'atoz') {
        sorted = [...productCollection].sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
      }
      // ZtoA
      // if (type == 'ztoa') {
      if (type === 'ztoa') {
        sorted = [...productCollection].sort((a, b) => b[sortProperty].localeCompare(a[sortProperty]));
      }


      setData(sorted);
    };


  sortArray(sortType);
}, [sortType, props.items]);







  return (
    <>
    <StateConsumer>

    {({updateGridListing, gridListing, updateModal, modalType, modal}) => {



  return (
    <>



   <div class="shop-top-bar shop-top-bar-flex mb-40 mb-xs-20">
    <div class="shop-topbar-left">
        <p>Showing 1-{productCount} of {productCount}</p>
    </div>
    <div class="shop-topbar-right shop-tab-flex">

    <div class="ccnFancySelectWrapper">
    <div class="ccnFancySelectLabel">Sort by: </div>
    <select className="ccnFancySelect" onChange={(e) => setSortType(e.target.value)}>
        <option value="featured">Featured</option>
        <option value="atoz">A to Z</option>
        <option value="ztoa">Z to A</option>
    </select>
    </div>




        <div class="shop-tab nav">
            <a onClick={v => updateGridListing(1)} class="active">
                <i class="fa fa-th"></i>
            </a>
            <a onClick={v => updateGridListing(0)}>
                <i class="fa fa-list"></i>
            </a>
        </div>
    </div>
  </div>



   <div class="product-area-wrap">
    <div class="row">

{productCollection && data.map((item, key) => {

  return (
    <SingleProduct item={item} key={key} entityData={entityData} />
  )
}

)}

</div>

    </div>



  </>
)}}
</StateConsumer>

  </>
)
// }
// return null
}
