import React from 'react';
import GatsbyLink from 'gatsby-link';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function SectionFaq(props){

  const {
    field_formlight_bg_color,
    field_formlight_icon_css_class,
    field_title,
    field_formlight_paragraph_item
  } = props.data

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }

  return (
    <div className={`faq-section section pt-100 pt-lg-80 pt-md-70 pt-sm-60 pt-xs-50 ${backgroundColor}`}>
    <div class="container">
        <div class="row mb-60 mb-lg-40 mb-md-30 mb-sm-30 mb-xs-20">
            <div class="col-12">
                <div class="section-title-five text-center">
                    {field_formlight_icon_css_class &&
                      <div class="section-title-icon">
                        <i className={field_formlight_icon_css_class}></i>
                      </div>
                    }
                    {field_title &&
                      <h2>{field_title}</h2>
                    }
                </div>
            </div>
        </div>
        <div class="row">
          {field_formlight_paragraph_item.map((item, k) => {
            return (
              <div class="col-md-6">
                <div class="faq-content mb-60 mb-md-50 mb-sm-40 mb-xs-30">
                  <h2 class="title">{item.field_formlight_faq_question}</h2>
                  {ReactHtmlParser(item.field_formlight_faq_anwser)}
                </div>
              </div>
            )
          })}
        </div>
    </div>
</div>
  );
}
