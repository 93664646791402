import React from 'react';
import GatsbyLink from 'gatsby-link';


export default function SectionCtaSimple(props) {

  const {
    field_button_text,
    field_description,
    field_formlight_bg_color,
    field_title,
    field_link
  } = props.data

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }

  return (
    <div className={`help-section section pt-100 pb-100 pt-lg-80 pb-lg-80 pt-md-70 pb-md-70 pt-sm-60 pb-sm-60 pt-xs-50 pb-xs-50 ${backgroundColor}`}>
      <div class="container">
          <div class="row">
              <div class="col-12">
                  <div class="help-content">
                      {field_title && <h2>{field_title}</h2>}
                      {field_link && field_button_text && <a href={field_link} class="shop-btn">{field_button_text}</a>}
                  </div>
              </div>
          </div>
      </div>
  </div>

  );

}
