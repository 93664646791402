import React, { useState } from 'react';
import GatsbyLink from 'gatsby-link';
import img3 from '../../images/img3.jpg';
import ModalVideo from 'react-modal-video';
import ReactHtmlParser from 'react-html-parser';
import Img from "gatsby-image"

export default function SectionAboutVideo(props){

  const [isOpen, setIsOpen] = useState(false);

  const {
    field_button_text,
    field_description,
    field_formlight_bg_color,
    field_image_media,
    field_link,
    field_title,
    field_video_url,
    fieldImageMedia
  } = props.data;

  const lazyImage = fieldImageMedia.childImageSharp.fluid

  let videoChannel = '',
      videoId = ''

  if(field_video_url !== null){
    if(field_video_url.includes('vimeo')){
      videoChannel = 'vimeo'
      videoId = field_video_url.split('vimeo.com/')[1]
    } else if(field_video_url.includes('youtube')){
      videoChannel = 'youtube'
      videoId = field_video_url.split('?v=')[1]
    }
  }

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }

  return (
    <div class={`about-section section pt-100 pb-100 pt-lg-80 pt-md-70 pt-sm-55 pt-xs-45 ${backgroundColor}`}>
      <div class="container">
          <div class="row align-items-center">
              <div class="col-lg-7 col-md-6">
                  <div class="about-area">
                      {field_title && <h2 class="title">{field_title}</h2>}
                      {field_description && ReactHtmlParser(field_description)}
                      {field_link && field_button_text && <a href={field_link} className="read-btn border-none">{field_button_text}</a>}
                  </div>
              </div>
              <div class="col-lg-5 col-md-6">
                  <div class="about-intro-image">
                      <div class="about-image">
                        {fieldImageMedia !== null && <Img fluid={lazyImage} alt={field_title}/>}
                      </div>
                    {field_video_url &&
                      <div class="play-btn-area">
                          <a onClick={(v) => { setIsOpen(true) }} class="play-btn video-popup"><img src="https://demo.hasthemes.com/raz-preview/raz/assets/images/icons/play-btn-02.png" alt="" /></a>
                          {videoChannel !== '' && videoId !== '' && <ModalVideo channel={videoChannel} isOpen={isOpen} videoId={videoId} onClose={() => setIsOpen(false)} />}
                      </div>
                    }
                  </div>
              </div>
          </div>
      </div>
  </div>

  );
}
