import React from 'react';
import { Box, Text } from 'rebass';
import GatsbyLink from 'gatsby-link';

const BreadcrumbListItem = (props) => {
return (
     <li
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
      {props.link ? ( <BreadcrumbListItemLink link={props.link}> {props.children} </BreadcrumbListItemLink> )
        : (
          <>
          {props.children}
          </>
        )
      }
      </li>
    )};

const BreadcrumbListItemLink = (props) => {
return (
     <GatsbyLink to={props.link} itemProp="item" itemProp="name">{props.children}</GatsbyLink>
    )};


const Breadcrumbs = ({
  productTitle,
  collectionTitle,
  collectionPath,
  separator,
  title
}) => {
  return (
    <>
    <ul class="page-breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
      <BreadcrumbListItem link={"/"}>
          Home
      </BreadcrumbListItem>
      {title ? (
        <BreadcrumbListItem>{title}</BreadcrumbListItem>
      ) : (
        ''
      )}

    </ul>
    {/*
      <Box fontSize={[2]} itemScope itemType="https://schema.org/BreadcrumbList">


      {collectionPath && collectionTitle ? (
        <Box as="span">
          <Text as="span" mx={1} variant="link">
            {separator}
          </Text>
          <Box
            as="span"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <GatsbyLink to={`${collectionPath}`} mr={1} itemProp="item">
              <Text as="span" itemProp="name" variant="link">
                {collectionTitle}
              </Text>
            </GatsbyLink>
          </Box>
        </Box>
      ) : (
        ''
      )}

      {productTitle ? (
        <Box as="span">
          <Text as="span" mx={1}>
            {separator}
          </Text>
          <Box
            as="span"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            <Text as="span" itemProp="name">
              {productTitle}
            </Text>
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
    */}
    </>
  );
};

export default Breadcrumbs;
