import React from 'react';
import Img from "gatsby-image"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Box } from 'rebass';

export default function SectionAbout(props){

  const {
    field_description,
    field_formlight_bg_color,
    field_image_media,
    field_title,
    fieldImageMedia
  } = props.data;


  let lazyImage
  if(fieldImageMedia !== null) lazyImage = fieldImageMedia.childImageSharp.fluid

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }

  return (
    <div class={`about-section section pb-100 pt-100 pt-lg-80 pt-md-60 pt-sm-50 pt-xs-45 ${backgroundColor}`}>
      <div class="container">
        <div class="row align-items-center">
          {fieldImageMedia !== null &&
            <>
              <div class="col-lg-5 col-md-6">
                <div class="about-intro-image">
                  <div class="about-image">
                    {fieldImageMedia !== null && <Img fluid={lazyImage} alt={field_title}/>}
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-6">
                <div class="raz-about-content">
                  <h2>{field_title}</h2>
                  {ReactHtmlParser(field_description)}
                </div>
              </div>
            </>
          }
          {fieldImageMedia == null &&
            <div class="col-12 col-xl-10 offset-xl-1">
              <Box pl={[0,0,0,`80px`]} pr={[0,0,0,`80px`]} className="raz-about-content">
                <h2 className="text-center mb-20">{field_title}</h2>
                {ReactHtmlParser(field_description)}
              </Box>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
