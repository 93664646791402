import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SingleProduct from '../Product/SingleProduct';
import ProductWrapper from '../Product/ProductWrapper';
import GatsbyLink from 'gatsby-link';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PopularProducts(props){

  const {
    products,
    entityData
  } = props

  const {
    field_button_text,
    field_description_plain,
    field_link,
    field_title,
    field_formlight_paragraph_item
  } = props.data

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className="ccnPopProds section pt-80 pb-70">
      <div className="container-fluid container-fluid-minimus">
        <div className="row">
          <div className="col-12">
            <div className="section-title text-center before-none mb-30">
              <h2>{field_title}</h2>
              <p>{field_description_plain}</p>
            </div>
          </div>
        </div>
        <div className="description-nav">
          <Tabs className="ccnDescTabs ccnLarge mb-0" value={value} onChange={handleChange}>
            {field_formlight_paragraph_item.map((item, k) => {
              const {
                field_tab_name
              } = item
              return (
                <Tab className="ccnDescTab" label={field_tab_name} {...a11yProps(k)} />
              )
            })}
          </Tabs>
        </div>
        <div className="row">
          <div className="col-lg-12">
          {field_formlight_paragraph_item.map((item, k) => {

            const {
              field_tab_products
             } = item

            let onlyProducts = []
            field_tab_products.map(item => onlyProducts.push(parseInt(item.id)))
            onlyProducts = products.filter(item => onlyProducts.includes(item.node.nodeId)).map(item => item = item.node)

            return (
              <TabPanel value={value} index={k}>
                <ProductWrapper items={onlyProducts} entityData={entityData} />
              </TabPanel>
            )
          })}
          </div>
        </div>
        <div className="row text-center">
          <div className="col-12">
            <div className="btn-group">
              <GatsbyLink className="bk-btn ml-2 mr-2 mb-2 mt-2" to={field_link}>{field_button_text}</GatsbyLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
