import React from 'react';
import GatsbyLink from 'gatsby-link';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function SectionContactDetails(props){

  const {
    field_description,
    field_title,
    field_google_url,
    field_formlight_paragraph_item
  } = props.data

  return (
    <div class="help-section section ">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 pl-xl-0">
            <iframe width="auto" height="500" frameBorder="0" className="contactDetailMap" src={field_google_url} />
          </div>
          <div class="col-md-6">
            <div class="pt-100 pb-100 infoCol">
              {field_title && <h2>{field_title}</h2>}
              {field_description && <div className="intro">{ ReactHtmlParser(field_description) }</div>}
              <div class="row">
                {field_formlight_paragraph_item.map((item, k) => {
                  return (
                    <div class="col-md-4 col-sm-4 col-12">
                      <div class="contactDetailSection">
                        <i className={`dlicon ui-2_phone ${item.field_icon}`}></i>
                        <div className="contactDetailSectionInner">
                          <h3>{item.field_title}</h3>
                          {ReactHtmlParser(item.field_description)}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
