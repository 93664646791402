import React from 'react';
import GatsbyLink from 'gatsby-link';
import Breadcrumbs from '../../Commons/Breadcrumbs'

function Header1(props) {

  const {
    field_title,
    field_formlight_bg_color
  } = props.data

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }


  return (
    <div className={`breadcrumb-section section bg-image ${backgroundColor}`}>
      <div class="container">
        <div class="row">
          <div class="col">
            {field_title &&
              <div class="breadcrumb-title text-center">
                <h1>{field_title}</h1>
                <Breadcrumbs title={field_title} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header1;
