import React from 'react';
import GatsbyLink from 'gatsby-link';
export default function Features3Col(props){

  const {
    field_columns,
    field_formlight_bg_color,
    field_formlight_paragraph_item,
    field_title,
} = props.data;

let backgroundColor = ''

if(
  typeof field_formlight_bg_color !== 'undefined'
  && field_formlight_bg_color !== null
  && field_formlight_bg_color !== ''
){
  backgroundColor = 'bg-' + field_formlight_bg_color
}

  if(field_columns === '4cols') {
    return (
      <div className={`feature-section section pt-60 pb-60 pt-lg-80 pt-md-70 pt-sm-60 pb-sm-60 ${backgroundColor}`}>
          <div class="container-fluid container-fluid-minimus pl-140 pl-lg-15 pl-md-15 pl-sm-15 pl-xs-15 pr-140 pr-lg-15 pr-md-15 pr-sm-15 pr-xs-15">
              <div class="row">
                {field_formlight_paragraph_item.map((item, k) => {
                  return (
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <div class="single-feature mb-15 mt-15 mt-md-0">
                            <div class="feature-icon">
                                <i class={`dlicon ${item.field_icon}`}></i>
                            </div>
                            <div class="feature-content">
                                <h3 class="title">{item.field_title}</h3>
                                <p>{item.field_subtitle}</p>
                            </div>
                        </div>
                    </div>
                  )
                })}
              </div>
          </div>
      </div>
    )
  }
  return (
    <div className={`choose-us-section section pb-65 pt-85 pt-lg-65 pt-md-45 pt-sm-45 pt-xs-45 ${backgroundColor}`}>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title text-center before-none">
                        <h2>{field_title}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                {field_formlight_paragraph_item.map((item, k) => {
                  return (
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="single-choose-item mb-50 mb-sm-30 mb-xs-30 mt-30">
                            <div class="chosse-icon">
                                <i className={`dlicon ${item.field_icon}`}></i>
                            </div>
                            <div class="choose-content">
                                <h3 class="title">{item.field_title}</h3>
                                <p>{item.field_subtitle}</p>
                            </div>
                        </div>
                    </div>
                  )
                })}
            </div>
        </div>
    </div>

  );
}
