import React from 'react';
import GatsbyLink from 'gatsby-link';
import Breadcrumbs from '../../Commons/Breadcrumbs'
import Img from "gatsby-image"

function Header2(props) {

  const {
    field_image_media,
    field_title,
    fieldImageMedia
  } = props.data

  let sectionStyle = ``

  const lazyImage = fieldImageMedia.childImageSharp.fluid
  // console.log(lazyImage)

  if(typeof field_image_media !== 'undefined' && lazyImage !== null){
    sectionStyle = {
      // backgroundImage: `url(${lazyImage})`
      objectFit: `cover`,
      position: `absolute`,
      width: `100vw`,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }
  }

  return (
    <>
      <div class="breadcrumb-section-two section bg-image">
            <Img fluid={lazyImage} alt={field_title} style={sectionStyle}/>
            <div class="container">
                <div class="row">
                    <div class="col">
                        {field_title &&
                          <div class="breadcrumb-title-two text-center">
                            <h1>{field_title}</h1>
                            <Breadcrumbs title={field_title} />
                          </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Header2;
