import React from "react";
import { StateConsumer } from '../../context/StateContext'
import GatsbyLink from 'gatsby-link';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Img from "gatsby-image"
import ConfigureActions from './ConfigureActions'
import ReadMore from '../Commons/ReadMore'
import ProductBuilderIcon from '../Commons/ProductBuilderIcon'

export default function SingleProduct({
  onClickModal,
  item,
  entityData
}) {

if (item){


  const {
    nodeType,
    nodeName,
    imageGroup,
    nodeImages,
    childImageSharp,
    nodePbItems,
    pageUrl,
    nodeBody,
    nodeId
  } = item;



  let pbData = [];
  if(
    nodePbItems
    && nodePbItems.length
  ){
    nodePbItems.map((item, i) => {
      let itemType = item._b;
      let itemKey = item.key;
      let itemLink = entityData.v[itemType][itemKey];
      if(itemLink){
        pbData.push(itemLink);
      }
    })
  }


  let lazyImages = null,
      remoteImages = null

  if(imageGroup !== null && imageGroup.length > 0){

    lazyImages = imageGroup.slice(0, 2).map((item, k) => {

      if(k === 0){

        // console.log(item.childImageSharp)
        return (
          <>
          {item.childImageSharp !== null && <Img fluid={item.childImageSharp.fluid} alt={nodeName} className="img-fluid imgFirst" objectFit="contain" />}
          {item.childImageSharp === null && <img src={nodeImages[k]} alt="" className="img-fluid imgFirst"/>}
          </>
        )
      }
      return (
        <>

        {item.childImageSharp !== null && <Img fluid={item.childImageSharp.fluid} alt={nodeName} className="img-fluid imgSecond" />}
        {item.childImageSharp === null && <img src={nodeImages[k]} alt="" className="img-fluid imgSecond" />}
        </>
      )
    })
  }

if(item.nodeImages !== null && item.nodeImages.length > 0){
  remoteImages = item.nodeImages.slice(0, 2).map((image, index) => {
      return (
        <img src={image} class="img-fluid" alt="" />
      )
  })
}



  let configItems = [];

  const pbItemCollection = (dataSource, key) => {
    let finalCollection = [];
    dataSource.map((product, index) => {
      finalCollection.push(... product[key])
    })
    finalCollection = new Set(finalCollection)
    return finalCollection
  }


  let configOptions = item.nodePbItems.filter(f => f._b !== "simple_iten")
  // let configOptions = item.nodePbItems
  let configOptionsCount = configOptions.length


  let configOptionsString = "+ options";

  if(nodeType && nodeType === "Fixture"){
    configOptionsString = "+ Fixtures";
  }

  configItems.push({
    count: configOptionsCount,
    string: configOptionsString,
    icon: 'fixture'
  });


  if(nodeType && nodeType === "Fixture"){
    let lampTypeCount = pbItemCollection(item.nodePbItems, 'lamp_types').size;
    let lampTypeString = "+ Lamp Types"
    let lensTypeCount = pbItemCollection(item.nodePbItems, 'lens').size
    let lensTypeString = "+ Lens Types"

    configItems.push(
      {
        count: lampTypeCount,
        string: lampTypeString,
        icon: 'lampType'
      },
      {
        count: lensTypeCount,
        string: lensTypeString,
        icon: 'lens'
      }
    );
  }

  if(nodeType && nodeType === "Lamp Type"){
    let lampKelvinCount = pbItemCollection(pbData, 'lk').size;
    let lampKelvinString = "+ Kelvin values";
    if(lampKelvinCount > 0){
      configItems.push(
        {
          count: lampKelvinCount,
          string: lampKelvinString
        }
      );
    }
    let lampDimmingCount = pbItemCollection(pbData, 'ld').size;
    let lampDimmingString = "Supports dimming";
    if(lampDimmingCount > 0){
      configItems.push(
        {
          count: '',
          string: lampDimmingString
        }
      );
    }


  }









  if(nodeType && nodeType === "Mounting"){

    let mountingPositionsCount = pbItemCollection(pbData, 'mounting_position').size
    let mountingPositionString = "+ mounting positions"
    configItems.push(
      {
        count: mountingPositionsCount,
        string: mountingPositionString
      }
    );

    // let switchLegs = pbItemCollection(options, 'ls').size
    // let switchLegsString = "<i class='fa fa-check'></i> Switch legs"
    // if(switchLegs > 0){
    //   configItems.push(
    //     {
    //       count: '',
    //       string: switchLegsString
    //     }
    //   );
    // }
  }








  // let lampTypes = pbItemCollection(item.pb_items, 'lamp_types').size + '+ Lamp Options'
  // let lensTypes = pbItemCollection(item.pb_items, 'lens').size + '+ Lens Types'

  let productTitle = item.nodeName,
      productAlias = item.pageUrl,
      productDescr = ReactHtmlParser(item.nodeBody),
      productId = item.nodeId



    const PrintConfigs = props => {

      const {
        showIcons
      } = props

      return (
        <div className="product-meta-surround">
          {configItems.map((item, i) => {
            const {
              count,
              string,
              icon
            } = item

            if(count > 0) return (
              <p class="product-meta product-builder-meta">
                {showIcons === true && <ProductBuilderIcon item={icon} />}
                {ReactHtmlParser(count + string)}
              </p>
            )
            return null
          })}
        </div>
      )
    }

    return (
      <StateConsumer>
        {({updateGridListing, gridListing, updateModal, modalType, modal}) => {

        if (gridListing === false){
          return (
            <div class="col-12 list-prod">
              <div class="shop-list-wrap ccnCard ccnProductCard mb-30">
                <div class="row">
                  <div class="col-lg-4 col-md-4">
                    <div class="product-img-list">
                      <GatsbyLink to={productAlias}>
                        {lazyImages}
                      </GatsbyLink>
                      <div class="product-quickview-list">
                        <a title="Quick Shop" onClick={(v) => {updateModal(item, 'product')}}>Quick View</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 col-md-8">
                    <div class="shop-list-content">
                      <div class="product-details-content quickview-content">
                        <h3><GatsbyLink to={productAlias}>{productTitle}</GatsbyLink></h3>
                        {/*<ReadMore color="#fff"  title="Read more">{productDescr}</ReadMore>*/}
                        {/*{configItems && <PrintConfigs showIcons />}*/}
                        {configOptions.length > 0 && <ConfigureActions array={configOptions} arrayItemKey={'key'} arrayItemTitle={'_n'} productId={productId} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        return (
          <div class="col-xxl-3 col-xl-4 col-sm-6 col-12">
            <div class="single-grid-product ccnCard ccnProductCard mb-70 mb-lg-50 mb-md-50 mb-sm-30 mb-xs-30">
              <div class="product-image">
                <GatsbyLink to={productAlias}>
                  {lazyImages}
                </GatsbyLink>
                <div class="product-action">
                  <ul>
                    <li><a onClick={(v) => {updateModal(item, 'product')}} title="Quick View"><i class="fa fa-search-plus"></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="product-content text-left">
                <h3 class="title"><GatsbyLink to={productAlias}>{productTitle}</GatsbyLink></h3>
                {configItems && <PrintConfigs />}
              </div>
            </div>
          </div>
        )}}
      </StateConsumer>
    )
  }
  return null
}
