import React, { useState, useContext, useEffect } from 'react'
import GatsbyLink from 'gatsby-link'
import LoaderCenterHorizontal from '../Commons/LoaderCenterHorizontal'
import StateContext from '../../context/StateContext'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined'
import Captcha from './Captcha'
import { useForm } from "react-hook-form"
import { useStaticQuery, graphql } from "gatsby"
import { sessToken, submitWebform } from "../../helpers/DrupalHelper"
import { excludeNodeEnv } from "../../helpers/DomHelper"
import styled, { css } from 'styled-components'

const Input = React.forwardRef(({ label, name, placeholder, register }, ref) => (
  <div className={`form-field form-field-input ${name}`}>
    <label>{label}</label>
    <input placeholder={placeholder} name={name} ref={ref} />
  </div>
));

const TextArea = React.forwardRef(({ label, name, placeholder, register }, ref) => (
  <div className={`form-field form-field-textarea ${name}`}>
    <label>{label}</label>
    <textarea placeholder={placeholder} name={name} ref={ref}></textarea>
  </div>
));

const Submit = React.forwardRef(({ label, name, register }, ref) => (
  <div className={`form-field form-field-submit ${name}`}>
    <input type="submit" value={label} />
  </div>
));

const ReCaptcha = React.forwardRef(({ label, name, register }, ref) => (
  <div class="g-recaptcha" data-sitekey="6Le1248UAAAAAMs9iyMZwXi1xylMXHYK7cJNSJRe" data-theme="light" data-type="image"></div>
));

const FieldError = styled.div`
  color: #fc4f4f;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    display: block;
    font-size: 1.3rem;
  }
`

export default function ContactForm(props){

  const {
    field_formlight_bg_color,
    field_title,
    field_webform
  } = props.data

  const { updateAlert } = useContext(StateContext)

  const [sending, setSending] = useState(false)

  const onVerifyCaptcha = (token) => {
    setValue('captchaToken', token);
  };

  useEffect(() => {
    register({ name: 'captchaToken' }, { required: true });
  });



  const query = useStaticQuery(graphql`
    query allFormlightWebform {
      allFormlightWebform {
        nodes {
          webformId
          webformFields
        }
      }
    }
  `)

  let webform = query.allFormlightWebform.nodes.filter(f => f.webformId === field_webform)
      webform = webform[0]

  const {
    webformId,
    webformFields
  } = webform

  const { handleSubmit, errors, watch, reset, formState, register, setValue } = useForm({
   mode: 'onChange'
 });

  const onSubmit = async data => {

    await setSending(true)

    await sessToken().then(async response => {
      await submitWebform(response, webformId, data)
        .then(async response => {
          reset()
          updateAlert(`Thanks ${data.name}, we'll get back to you soon.`)
        })
      })

    setSending(false)

  }

  let backgroundColor = ''

  if(
    typeof field_formlight_bg_color !== 'undefined'
    && field_formlight_bg_color !== null
    && field_formlight_bg_color !== ''
  ){
    backgroundColor = 'bg-' + field_formlight_bg_color
  }

  if(webformFields.length){
    return (
      <div className={`section pt-100 pb-100 ${backgroundColor}`}>
        <div class="container">
          {field_title &&
            <div class="row">
              <div class="col-12">
                <div class="section-title text-center before-none mb-30">
                  <h2>{field_title}</h2>
                </div>
              </div>
            </div>
          }
          <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
              <form onSubmit={handleSubmit(onSubmit)} className="contact__form--3" style={{position: `relative`}}>
                {sending === true &&
                  <LoaderCenterHorizontal inlineOverlay />
                }
                {webformFields.map((item, k) => {

                  const {
                    "#type": type,
                    "#title": title,
                    "#webform_key": name
                  } = item

                  if(type === 'captcha' && excludeNodeEnv() === true) {
                    let script = document.createElement("script")
                    script.src = "https://www.recaptcha.net/recaptcha/api.js?hl=en"
                    script.async = true;
                    document.head.appendChild(script)
                  }
                  return (
                    <>
                      {(type === 'textfield' || type === 'email' || type === 'webform_name') &&
                        <Input placeholder={`${title}*`} name={name} ref={register({ required: true })} />
                      }

                      {type === 'textarea' &&
                        <TextArea placeholder={`${title}*`} name={name} ref={register({ required: true })} />
                      }

                      {/* {type === 'captcha' && excludeNodeEnv() === true &&
                        <Captcha onVerifyCaptcha={onVerifyCaptcha} />
                      } */}

                      {errors[name] &&
                        <FieldError><ErrorOutlineOutlinedIcon /> This field is required</FieldError>
                      }
                    </>
                  )
                })}
                {/*<Submit label="Send" name="submit_button" />*/}
                <Captcha onVerifyCaptcha={onVerifyCaptcha} />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return "No Webform fields found"
}
