import React from 'react';
import Slider from "react-slick";
import GatsbyLink from 'gatsby-link';
import Img from "gatsby-image"
import SliderArrow from '../Commons/SliderArrow'
import { useStaticQuery, graphql } from "gatsby"
import { StateConsumer } from '../../context/StateContext'

export default function RecentBuilds(props){

const data = useStaticQuery(graphql`
  query allFormlightBuild {
    allFormlightBuild(limit: 10) {
      edges {
        node {
          part_number
          title
          alias
          images
          imageGroup {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`)


  const {
    field_button_text,
    field_link,
    field_title,
    field_description_plain,
    field_formlight_bg_color
  } = props.data

   let backgroundColor = 'bg-white'

   if(
     typeof field_formlight_bg_color !== 'undefined'
     && field_formlight_bg_color !== null
     && field_formlight_bg_color !== ''
   ){
     backgroundColor = 'bg-' + field_formlight_bg_color
   }

   const settings = {
     dots: false,
     infinite: true,
     speed: 500,
     slidesToShow: 4,
     slidesToScroll: 1,
     nextArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-right" />,
     prevArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-left" />,
     responsive: [
       {
         breakpoint: 1380,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 3,
         }
       },
       {
         breakpoint: 992,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 2,
           initialSlide: 2
         }
       },
       {
         breakpoint: 690,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1,
           initialSlide: 1,
           arrows: false
         }
       }
     ]
   };

   return (
     <StateConsumer>
      {({updateGridListing, gridListing, updateModal, modalType, modal, addToCart}) => {
        const viewProduct = (product, index) => {
          updateModal(product, 'three', index, null, null)
        }

        return (
          <div className={`ccnRelatedCaroM portfolio-related-section section pt-80 pt-lg-70 pt-md-60 pt-sm-50 pt-xs-40 pb-100 pb-lg-100 pb-md-90 pb-sm-80 pb-xs-70 ${backgroundColor}`}>
            <div className="container-fluid container-fluid-minimus">
              <div className="row">
                <div className="col-12">
                  <div className="section-title text-center before-none mb-30">
                    {field_title && <h2>{field_title}</h2>}
                    {field_description_plain && <p>{field_description_plain}</p>}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Slider {...settings} className="relatedCaroSlider">
                    {data && data.allFormlightBuild.edges.map((item, key) => {

                      const {
                        title,
                        part_number,
                        alias,
                        images,
                        imageGroup
                      } = item.node

                      let imageSrc = null

                      if(images.length) {
                        imageSrc = images[0]
                      }
                      if(imageGroup !== null && imageGroup.length){
                        imageSrc = imageGroup[0].childImageSharp.fluid
                      }

                      const LocalImage = ({ image }) => {
                        return <Img fluid={imageSrc} alt={part_number} className="img-fluid"/>
                      }

                      const RemoteImage = ({ image }) => {
                        return <img src={imageSrc} alt={part_number} className="img-fluid"/>
                      }
                      if(imageSrc !== null){
                        return(
                          <div key={key} className="ccnMasonryItem element-item">
                            <div className="single-grid-product ccnCard">
                              <div className="product-image">
                                <a onClick={(v) => { viewProduct(item.node, key)}}>
                                  {typeof imageSrc === 'object' && <LocalImage image={imageSrc} />}
                                  {typeof imageSrc !== 'object' && <RemoteImage image={imageSrc} />}
                                </a>

                                <div className="product-action">
                                  <ul>
                                    <li><a title="Quick Shop" onClick={(v) => { viewProduct(item.node, key) }}><i className="fa fa-search-plus"></i> <span className="ccnL">View</span><span className="ccnS">View</span></a></li>
                                    <li><a onClick={(v) => { addToCart({handle: title })} }><i className="fa fa-shopping-cart"></i> <span className="ccnL">Add to Cart</span><span className="ccnS">Buy</span></a></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="product-content">
                                <h3 className="title"><a onClick={(v) => { viewProduct(item.node, key) }}>{part_number}</a></h3>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      return null
                    })}
                  </Slider>
                </div>
              </div>
              <div className="row text-center mt-40">
                <div className="col-md-12 ">
                  <div className="btn-group">
                    {field_button_text && <GatsbyLink to="/inspiration" className="shop-btn shop-btn-white ml-2 mr-2 mb-2 mt-2">{field_button_text}</GatsbyLink>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}}
      </StateConsumer>
    );
}
