import React, { useState, useEffect } from 'react'
import GatsbyLink from 'gatsby-link'
import Slider from "react-slick"
import ReactHtmlParser from 'react-html-parser'
import styled, { css } from 'styled-components'

const SliderItem = styled('div')`
&&& {
  ${props => props.overlay === "light" && css`
    &:before {
      content: "";
      background: rgba(255,255,255,.5);
    }
    h1, p, .shop-btn {
      color: #1a1a1a;
    }
    .shop-btn {
      border-color: #1a1a1a;
      &:hover {
        background-color: #1a1a1a;
        color: #fff;
      }
    }
  `}
  ${props => props.overlay === "very-dark" && css`
    &:before {
      content: "";
      background: rgba(0,0,0,.8);
    }
  `}
}`

function SliderArrow(props) {
  const { className, style, onClick, buttonIcon } = props
  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <i className={buttonIcon}></i>
    </button>
  )
}

export default function HeroSlider(props){

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])



  const items = props.data.field_formlight_paragraph_item

  const settings = {
    className: "center ccnSlickSlider ccnHeroSlider1",
    centerMode: true,
    infinite: true,
    centerPadding: "400px",
    slidesToShow: 1,
    dots: false,
    speed: 500,
    nextArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-right"/>,
    prevArrow: <SliderArrow buttonIcon="dlicon arrows-1_tail-triangle-left"/>,
    responsive: [{
        breakpoint: 1600,
        settings: {
          centerPadding: "220px"
        }
      },
      {
        breakpoint: 1300,
        settings: {
          centerPadding: "120px",
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1130,
        settings: {
          centerPadding: "0",
          centerMode: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: "0",
          slidesToShow: 1
        }
      }
    ]
  }

  if(items.length > 0 && loaded === true){
    return (
      <Slider  {...settings} style={{backgroundColor: `#fff`}}>
        {items.length > 0 && items.map((item, key) => {
          const {
            field_background_image,
            field_background_position,
            field_button_text,
            field_description,
            field_link,
            field_overlay,
            field_title
          } = item

          let backgroundPosition = `50% 50%`
          if(field_background_position === "1"){
            backgroundPosition = `50% 0`
          }
          if(field_background_position === "3"){
            backgroundPosition = `50% 70%`
          }

          return (
            <div>
              <SliderItem className="ccnSlideItem" overlay={field_overlay} style={{backgroundImage: `url(${field_background_image})`, backgroundPosition: backgroundPosition, backgroundSize: `cover`}}>
                <div className="ccnSlideItemInner">
                  <h1>{field_title}</h1>
                  <p>{ReactHtmlParser(field_description)}</p>
                  <GatsbyLink className='shop-btn' to={field_link}>{field_button_text}</GatsbyLink>
                </div>
              </SliderItem>
            </div>
          )
        })}
      </Slider>
    )
  }
  return null
}
